import React from 'react';
import { omit, isEmpty } from 'lodash';
import { EMPLOYEE_ACTIVE_DELIMITER_REGEX } from 'config';


export const showPractice = (employeePractices, id) => {
  let find = employeePractices.find(p => p.id === id)
  if (find !== undefined && find !== null) {
    return find.name + " [" + find.abbrev + "]"
  }
  return "Practice Not Found"
}

export const showSector = (industrySectors, id) => {
  let find = industrySectors.find(s => s.id === id)
  if (find !== undefined && find !== null) {
    return find.name
  }
  return "Sector Not Found"
}

const isEmptyParent = (skills, id) => {
  let found = skills.find(s => s.id === id);
  if (found !== undefined) {
    return found.parentId === undefined;
  }
  return false;
};

export const getMidLevelSkills = skills => {
  const parentSkills = skills.filter(s => s.parentId !== undefined && isEmptyParent(skills, s.parentId));
  return parentSkills;
};

export const getTopLevelSkills = skills => {
  const topLevelSkills = skills
    .filter(s => s.parentId === undefined)
    .map(s => {
      return {
        ...omit(s, 'parentId')
      };
    });
  return topLevelSkills;
};

export const getSkillTreeRecursive = skills => {
  const getSkillTreeRecursiveHelper = (skills, parents) => {
    return parents
      .map(parent => {
        const childSkill = getSkillTreeRecursiveHelper(skills, skills.filter(skill => skill.parentId === parent.id));
        if (childSkill.length > 0) {
          parent.children = childSkill;
        }
        return parent;
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  };

  return getSkillTreeRecursiveHelper(skills, skills.filter(skill => skill.parentId === undefined));
};

export const findSkillInTreeById = (id, skillTree) => {
  let stack = [...skillTree];
  while (stack.length) {
    let node = stack.pop();
    if (node.children && node.children.length) {
      node.children.forEach(st => stack.push(st));
    } else if (node.id === id) {
      return node;
    }
  }
  return null;
};

export const getMajor = (employee) => {
  if(employee.major?.isCapability){
    return employee.major
      ? employee.major.capability.name + " Capability/Specialization"
      : 'No Major';
  } else if (!employee.major?.isCapability){
    return employee.major

      ? employee.major.industry.name + " Industry"
      : 'No Major';
  } else {
    return "No Major";
  }
}

export const getMinor = (employee) => {
  if(employee.minor?.isCapability){

    return employee.minor
      ? employee.minor.capability.name + " Capability/Specialization"
      : 'No Minor';
  } else if (!employee.minor?.isCapability){
    return employee.minor
      ? employee.minor.industry.name + " Industry"
      : 'No Minor';
  } else {
    return "No Minor";
  }
}

export const changeDelimiter = str => {
  if (str === undefined || str === '') {
    return '';
  }
  return str.replace(EMPLOYEE_ACTIVE_DELIMITER_REGEX, ', ');
};

export const textOverflow = (fullText, limit) => {
  if (fullText === undefined || fullText === null) {
    return "";
  }
  return fullText.length > limit ? <span title={fullText}>{fullText.substr(0, limit).concat('...')}</span> : fullText;
};

export const getFullSkillTree = (skills, skillCategories) => {
  let basicSkillTree = getSkillTreeRecursive(skills);
  return skillCategories.map(c => ({
    id: c.id,
    name: c.name,
    children: basicSkillTree.filter(skill => skill.categoryId === c.id)
  }));
};

export const showParentSkills = skill => {
  let prevSkill = skill;
  let skills = [];
  while (!isEmpty(prevSkill)) {
    skills.push(prevSkill.name);
    prevSkill = prevSkill.parentSkill;
  }
  return skills.reverse().join(' / ');
};
